.rmc-picker-popup {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
}
.rmc-picker-popup-close {
  display: none;
}
.rmc-picker-popup-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-scrolling: touch;
  outline: 0;
}
.rmc-picker-popup-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 1050;
}
.rmc-picker-popup-mask-hidden {
  display: none;
}
.rmc-picker-popup-header {
  background-image: -webkit-linear-gradient(top, #e7e7e7, #e7e7e7, transparent, transparent);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e7e7e7), color-stop(#e7e7e7), color-stop(transparent), to(transparent));
  background-image: linear-gradient(to bottom, #e7e7e7, #e7e7e7, transparent, transparent);
  background-position: bottom;
  background-size: 100% 1px;
  background-repeat: no-repeat;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
}
.rmc-picker-popup-header-left,
.rmc-picker-popup-header-right {
  padding-left: 15px;
  padding-right: 15px;
}
.rmc-picker-popup-item {
  color: #0ae;
  font-size: 18px;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.rmc-picker-popup-item-active {
  background-color: #ddd;
}
.rmc-picker-popup-title {
  -webkit-box-flex: 1;
          flex: 1;
  color: #666;
  cursor: default;
}
.rmc-picker-popup-fade-enter,
.rmc-picker-popup-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
          animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rmc-picker-popup-fade-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
          animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rmc-picker-popup-fade-enter.rmc-picker-popup-fade-enter-active,
.rmc-picker-popup-fade-appear.rmc-picker-popup-fade-appear-active {
  -webkit-animation-name: rmcPopupPickerFadeIn;
          animation-name: rmcPopupPickerFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rmc-picker-popup-fade-leave.rmc-picker-popup-fade-leave-active {
  -webkit-animation-name: rmcPopupPickerFadeOut;
          animation-name: rmcPopupPickerFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rmcPopupPickerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rmcPopupPickerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes rmcPopupPickerFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rmcPopupPickerFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.rmc-picker-popup-slide-fade-enter,
.rmc-picker-popup-slide-fade-appear {
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%);
}
.rmc-picker-popup-slide-fade-enter,
.rmc-picker-popup-slide-fade-appear,
.rmc-picker-popup-slide-fade-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
          animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rmc-picker-popup-slide-fade-enter.rmc-picker-popup-slide-fade-enter-active,
.rmc-picker-popup-slide-fade-appear.rmc-picker-popup-slide-fade-appear-active {
  -webkit-animation-name: rmcPopupPickerSlideFadeIn;
          animation-name: rmcPopupPickerSlideFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rmc-picker-popup-slide-fade-leave.rmc-picker-popup-slide-fade-leave-active {
  -webkit-animation-name: rmcPopupPickerSlideFadeOut;
          animation-name: rmcPopupPickerSlideFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rmcPopupPickerSlideFadeIn {
  0% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes rmcPopupPickerSlideFadeIn {
  0% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@-webkit-keyframes rmcPopupPickerSlideFadeOut {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
}
@keyframes rmcPopupPickerSlideFadeOut {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
}
