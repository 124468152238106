.swal2-html-container {
    font-family: 'Roboto';
}

.swal2-title {
    font-family: 'Roboto-Bold';
}

.swal2-styled.swal2-cancel {
    background-color: #00316a;
}