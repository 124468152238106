@media screen and (min-width: 300px) and (max-width: 349px) {
    .viewContainer {
        min-width: 300px;
        padding-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 350px) and (max-width: 480px) {
    .viewContainer {
        min-width: 350px;
        padding-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .viewContainer {
        min-width: 450px;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (min-width: 769px) {
    .viewContainer {
        min-width: 600px;
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }
}

.signature {
    /* border: 1px black; */
    width: 80%;
    max-width: 500px;
    min-height: 250px;
    /* margin: auto; */
}